import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/Seo';

const PageTemplate = ({ data }) => {
	const page = data && data.page;

	return (
		<>
			<Seo
				metaTitle={page.seo?.metaTitle || page.title}
				shareTitle={page.seo?.shareTitle || page.title}
				metaDesc={page.seo?.metaDesc}
				shareDesc={page.seo?.shareDesc}
				shareGraphic={page.seo?.shareGraphic?.asset.url}
			/>
			<h1>{page.title}</h1>
		</>
	);
};

export default PageTemplate;

export const query = graphql`
	query legalPageQuery($_id: String!) {
		page: sanityLegalPage(_id: { eq: $_id }) {
			id
			title
			seo {
				...seoFields
			}
		}
	}
`;
